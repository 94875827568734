import React from "react"
import Helmet from "react-helmet"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomePageCarosuel from '../components/homepagecarousel/homepagecarousel'

// modal

export default () => (
  <Layout>
    <SEO
      title="Official Patient Site"
      keywords="VASCEPA, VASCEPA medication, icosapent ethyl"
      description="VASCEPA® (icosapent ethyl) is a prescription medication approved, along with certain medicines (statins), to reduce the risk of heart attack, stroke and certain types of heart issues. Please see Indication and Important Safety Information."
    />

    <Helmet
      bodyAttributes={{
        class: "home-page",
      }}
    />
    <HomePageCarosuel/>
    
  </Layout>
)
