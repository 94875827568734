import React from "react"
import { Link } from "gatsby"
import "./homepagecarousel.css"

import gradientDollar from "../../images/homecarousel/icons/0.0_Dollar_Icon_Gradient-01.png"
import gradientQuestion from "../../images/homecarousel/icons/0.0_QuestionMark_Icon_Gradient-01.png"
import gradientShield from "../../images/homecarousel/icons/0.0_Heart_Shield_Icon_Gradient-01.png"
import dollarIconGradient from "../../images/homecarousel/icons/dollar_icon_gradient.png"
import gradientChat from "../../images/homecarousel/icons/chatv2.svg"
import whiteDollar from "../../images/homecarousel/icons/0.0_Dollar_Icon_White-01.png"
import whiteQuestion from "../../images/homecarousel/icons/0.0_QuestionMark_Icon_White-01.png"
import whiteShield from "../../images/homecarousel/icons/0.0_Heart_Shield_Icon_White-01.png"
import dollarIconWhite from "../../images/homecarousel/icons/dollar_icon_white.png"
import whiteChat from "../../images/homecarousel/icons/0.0_msg_icon_white.png"
import medicalCross from "../../images/homecarousel/icons/medical_cross_gradient.png"
import medicalCrossWhite from "../../images/homecarousel/icons/medical_cross_white.svg"

import bigPill from "../../images/homecarousel/images/bigPill.png"
import vascepaCapsule from "../../images/homecarousel/images/vascepaCapsule.png"
import stethoscope from "../../images/homecarousel/images/stethoscope.png"
import dancingMan from "../../videos/0.0_Homepage_Animation.mp4"
import crystalHeart from "../../images/homecarousel/images/crystalHeart.png"

import chatMobile from "../../images/homecarousel/icons/chatMobile.png"
import bigPillMobile from "../../images/homecarousel/images/bigPillMobile.png"
import stethoscopeMobile from "../../images/homecarousel/images/stethoscope_mobile.png"
//import dollarSignMobile from "../../images/homecarousel/images/dollarSignMobile.svg"
import heartpillsMobile from "../../images/homecarousel/images/vascepaCapsuleMobile.png"
import crystalHeartMobile from "../../images/homecarousel/images/crystalHeartMobile.png"
//import questionMarkMobile from "../../images/homecarousel/images/questionMarkMobile.png"
import shieldMobile from "../../images/homecarousel/images/shieldMobile.png"
import goodRxMobile from "../../images/homecarousel/images/goodrx-mobile.png"
import goodRxDesktop from "../../images/homecarousel/images/goodrx-desktop.png"

import ExternalLink from "../../components/externalLink/externalLink"

var slideContent = [
  {
    backgroundImage: vascepaCapsule,
    alt: "Vascepa capsule",
    vimeoID: "https://player.vimeo.com/video/400606026?background=1",
    shieldIcon: "active",
    crossIcon: "in-active",
    dollarIcon: "in-active",
    static: true,
    right: "-130px",
    top: "0px"
  },
  {
    backgroundImage: goodRxDesktop,
    alt: "GoodRx",
    vimeoID: "https://player.vimeo.com/video/400401052?background=1",
    shieldIcon: "in-active",
    crossIcon: "active",
    dollarIcon: "in-active",
    static: true,
    right: "0px",
  },
  {
    backgroundImage: crystalHeart,
    alt: "Clear VASCEPA® (icosapent ethyl) capsule",
    vimeoID: "https://player.vimeo.com/video/400401054?background=1",
    shieldIcon: "in-active",
    crossIcon: "in-active",
    dollarIcon: "active",
    static: true,
    right: "-20px",
    top: "0px"
  },
]

var uniqueNum

export default class HomePageCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      render: ""
    }
  }

  componentDidMount() {
    this.setRender()
    document.addEventListener('resize', this.setRender)
    this.autoStartCarousel()
  }

  componentWillUnmount(){
    document.removeEventListener('resize', this.setRender)
  }

  setRender = () => {
    if (window.innerWidth < 1000){
      this.setState({
        render: "mobile"
      })
    }
      else{
        this.setState({
          render: "desktop"
        })
      }
    
  }

  startCarousel = () => {
    if (this.state.activeSlide < 1) {
      var nextSlide = this.state.activeSlide + 1
      this.setState({
        activeSlide: nextSlide,
      })
    } else {
      this.setState({
        activeSlide: 0,
      })
    }
  }

  carouselHover = event => {
    this.setState({
      activeSlide: parseInt(event.currentTarget.id),
    });
    clearInterval(uniqueNum);
  }
  carouselLeave = event => {
    clearInterval(uniqueNum);
  }
  autoStartCarousel = event => {
    uniqueNum = setInterval(this.startCarousel, 6000);
  }
  render() {
    var desktopView = (
      <div className="desktopView">
        <div className="row">
          <div className="col-6">

            <div
              onMouseEnter={this.carouselHover}
              onMouseLeave={this.carouselLeave}
              onFocus={this.carouselHover}
              onBlur={this.carouselLeave}
              id="0"
              className={
                "proof white-top-bar carousel-tab " +
                slideContent[this.state.activeSlide].shieldIcon
              }
            >
              <div className="copy">
                <h2 className="headline">
                  Eligible patients can pay 
                  <br className="hide-on-collapsed" /> as little as $9 for 90 days* 
                  <br className="hide-on-collapsed" /> with the VASCEPA Savings 
                  <br className="hide-on-collapsed" /> Card
                </h2>

                <Link
                  role="button"
                  to="/getting-started/vascepa-savings"
                  className="cta gradient-btn"
                >
                  See savings <span aria-hidden="true">&gt;</span>
                </Link>
                <p className="carousel-footnote left m-b-20">
                  *For eligible patients only. Maximum savings and other terms and conditions apply. See <a target="_blank" href="https://vascepa.copaysavingsprogram.com"> https://vascepa.copaysavingsprogram.com</a> for details.
                </p>
              </div>
              <img
                alt="hand dollar"
                src={dollarIconGradient}
                className="icon-active active-dollar-icon"
              ></img>
              <img
                alt="hand dollar"
                src={dollarIconWhite}
                className="icon-inactive"
              ></img>
            </div>

            <div
              onMouseEnter={this.carouselHover}
              onMouseLeave={this.carouselLeave}
              onFocus={this.carouselHover}
              onBlur={this.carouselLeave}
              id="1"
              className={
                "quiz white-top-bar carousel-tab " +
                slideContent[this.state.activeSlide].crossIcon
              }
            >
              <div className="copy">
                <h2 className="headline">
                For cash-paying patients,  
                <br className="hide-on-collapsed" /> an exclusive discount is
                <br className="hide-on-collapsed" /> available through 
                <br className="hide-on-collapsed" /> GoodRx
                </h2>
                <ExternalLink
                link="https://www.goodrx.com/vascepa?label_override=vascepa"
                label="Go to GoodRx >"
                styleName="cta gradient-btn"
              />
              </div>
              <img
                alt="heart in hand"
                src={medicalCross}
                className="icon-active"
              ></img>
              <img
                alt="heart in hand"
                src={medicalCrossWhite}
                className="icon-inactive"
              ></img>
            </div>

            <div
              onMouseEnter={this.carouselHover}
              onMouseLeave={this.carouselLeave}
              onFocus={this.carouselHover}
              onBlur={this.carouselLeave}
              id="2"
              className={
                "eligibility white-top-bar carousel-tab " +
                slideContent[this.state.activeSlide].dollarIcon
              }
            >
              <div className="copy">
                <h2 className="headline">
                  Added protection from
                  <br className="hide-on-collapsed" /> another CV event like
                  <br className="hide-on-collapsed" /> heart attack or stroke
                </h2>
                <Link
                  role="button"
                  to="/about-vascepa"
                  className="cta gradient-btn"
                >
                  Learn more <span aria-hidden="true">&gt;</span>
                </Link>
              </div>
              <img
                alt="heart shield"
                src={gradientShield}
                className="icon-active"
              ></img>
              <img
                alt="heart shield"
                src={whiteShield}
                className="icon-inactive"
              ></img>
            </div>
          </div>
          <div className="col-6 home-page-carousel-col-right">
            {slideContent[this.state.activeSlide].static ? (
              <div>
                <img
                  alt={slideContent[this.state.activeSlide].alt}
                  style={{
                    position: "absolute",
                    zIndex: "-1",
                    right: slideContent[this.state.activeSlide].right,
                    top: slideContent[this.state.activeSlide].top,
                    height: "100%",
                  }}
                  src={slideContent[this.state.activeSlide].backgroundImage}
                ></img>
                {slideContent[this.state.activeSlide].alt === "Vascepa capsule" ? (
                  <div className="vascepa-capsule-footnote">
                    <p className="footnote-font">Capsule is not actual size.</p>
                  </div>
                ) : null}
              </div>
            ) : (
              <iframe
                src={slideContent[this.state.activeSlide].vimeoID}
                width="551"
                height="531"
                frameborder="0"
                allow="autoplay; fullscreen"
                autoplay="1"
                allowfullscreen
              ></iframe>
            )}{" "}
          </div>
        </div>
      </div>
    )
    
    var mobileView = (
      <div className="mobileView">

        <div className="hero mobile-hero mobile-2nd-carousel">
          <div className="hero_icon-container ">
            <img src={dollarIconWhite} alt="question mark" />
          </div>

          <img
            src={heartpillsMobile}
            className="mobileHeroBackground mobile-left-12"
            alt="A clear VASCEPA® (icosapent ethyl) heart"
          ></img>
            <div className="vascepa-capsule-footnote">
              <p className="footnote-font">Capsule is not actual size.</p>
            </div>
          <div className="col-md-5 hero-content">
            <h2 className="carousel-header">
            Eligible patients can pay as little as $9 for 90 days* with the VASCEPA Savings Card
            </h2>
            <Link
              role="button"
              to="/getting-started/vascepa-savings"
              className="mobile-gradient-btn"
            >
              See savings <span aria-hidden="true">&gt;</span>
            </Link>
            <p className="carousel-footer">
            *For eligible patients only. Maximum savings and other terms and conditions apply. See <a target="_blank" href="https://vascepa.copaysavingsprogram.com"> https://vascepa.copaysavingsprogram.com</a> for details.
            </p>
          </div>
        </div>
        <div className="hero mobile-hero mobile-2nd-carousel">
          <div className="hero_icon-container ">
            <img src={medicalCrossWhite} alt="question mark" />
          </div>

          <img
            src={goodRxMobile}
            className="mobileHeroBackground mobile-left-12"
            alt="Good Rx Logo"
          ></img>
            <div className="vascepa-capsule-footnote">
            </div>
          <div className="col-md-5 hero-content">
            <h2 className="carousel-header">
            For cash-paying patients, an exclusive discount is available through GoodRx
            </h2>
            <ExternalLink
                link="https://www.goodrx.com/vascepa?label_override=vascepa"
                label="Go to GoodRx >"
                styleName="mobile-gradient-btn"
              />
          </div>
        </div>

        <div className="hero mobile-hero">
          <div className="hero_icon-container">
            <img src={whiteShield} alt="shield" />
          </div>

          <img
            src={crystalHeartMobile}
            className="mobileHeroBackground mobile-left-12"
            alt="Crystal heart vascepa"
          ></img>
          <div className="col-md-5 hero-content">
            <h2 className="carousel-header">
              Added protection from another CV event like heart attack or stroke
            </h2>
            <Link role="button" to="/about-vascepa" className="mobile-gradient-btn">
              Learn more <span aria-hidden="true">&gt;</span>
            </Link>
          </div>
        </div>
      </div>
    )

    if(this.state.render == "desktop"){
      var view = desktopView
    }
    else{
      var view = mobileView
    }
    
    
    return (
      <div className="home-page-carousel-outer">
        {view}
      </div>
    )
  }
}

